div#_company-billing_ {
  th {
    user-select: none;
    span {
      cursor: pointer;
    }
  }
  .status {
    padding: 3px 18px;
    font-weight: bold;
    border-radius: 50px;
    font-size: 12px;
    vertical-align: middle;
    &.red-s {
      background-color: var(--airbag-error-light);
      color: var(--airbag-error);
      stroke: var(--airbag-error);
    }
    &.yellow-s {
      background-color: var(--airbag-attention-light);
      color: var(--airbag-attention);
      stroke: var(--airbag-attention);
    }
    &.green-s {
      background-color: var(--airbag-successful-light);
      color: var(--airbag-successful);
      stroke: var(--airbag-successful);
    }
    &.grey-s {
      background-color: var(--airbag-grey-light);
      color: var(--airbag-dark-light);
    }
    .preloader-wrapper {
      width: 10px;
      height: 10px;
      margin-right: 10px;
      .spinner-layer {
        border-color: inherit;
      }
    }
  }

  .card-custom {
    background-color: var(--airbag-white);
    padding: 20px 0;
    border-radius: 6px;
    @media only screen and (max-width: 600px) {
      margin: 12px 0 18px 0;
    }
    .general-billing-container {
      display: flex;
      flex-direction: column;
      padding: 0 32px;
      text-align: center;
      margin: 6px 0;
      min-height: 55px;
      .general-billing-item {
        display: flex;
        justify-content: space-between;

        :nth-child(1) {
          // background-color: red;
          text-align: left;
        }

        :nth-child(3) {
          // background-color: red;
          text-align: right;
          width: 100%;
        }
      }

      svg {
        color: var(--airbag-og);
      }

      .kpi {
        margin: 0 0 2px 0;
        font-weight: bold;
        font-size: 14px;

        .kpi-icon {
          stroke: var(--airbag-grey);
          margin-right: 10px;
          width: 18px;
        }
      }
      * {
        width: 100%;
      }

      .price-per-user {
        text-align: left;
      }
    }
  }
}
